import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { withPrefix } from 'gatsby';
import * as styles from '../styles';
import Box from './Box';
import Grid from './Grid';
import Heading from './Heading';
import Paragraph from './Paragraph';
import Reveals from './Reveals';
import Section from './Section';

const productRegisters = [
  '/img/sa-product-register/brandsa-manufacture.svg',
  '/img/sa-product-register/brandsa-assemble.svg',
  '/img/sa-product-register/brandsa-supply.svg',
  '/img/sa-product-register/brandsa-install.svg'
];

const ProductRegistersWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 4rem auto 0;
  max-width: ${rem(800)};
  padding: 2rem 2rem 0;

  ${styles.media.lessThan('medium')`
    flex-flow: row wrap;
    padding: 2rem 1rem 0;
  `};
`;

const ProductRegistersImageWrapper = styled.div`
  flex: 1;
  margin-bottom: 2rem;
  padding: 0 1rem;

  ${styles.media.lessThan('medium')`
    flex: 1 0 50%;
    margin-bottom: 2rem;
    padding: 0 0.5rem;
  `};
`;

const IndustryAdvocateLogo = styled.img`
  margin-bottom: 2rem;
`;

const ProductRegisters = () => (
  <Section textAlign="center">
    <Grid.Row>
      <Grid.Column>
        <Box size="xsmall">
          <IndustryAdvocateLogo
            src="/img/sa-product-register/industry-advocate.svg"
            alt=""
            width="100"
          />
          <Heading type="h3">SA Product Register</Heading>
          <Paragraph>
            Our business supports the Industry Advocate’s SA Product Register
            initiative and have a range of SA products and services.
          </Paragraph>
        </Box>
        <ProductRegistersWrapper>
          {productRegisters.map((src, index) => (
            <ProductRegistersImageWrapper key={src}>
              <img src={src} alt="" width="91" />
            </ProductRegistersImageWrapper>
          ))}
        </ProductRegistersWrapper>
      </Grid.Column>
    </Grid.Row>
  </Section>
);

export default ProductRegisters;
