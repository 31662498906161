import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { withPrefix, Link } from 'gatsby';
import * as styles from '../styles';
import Grid from './Grid';

const Wrapper = styled.div`
  left: 0;
  padding: 4rem 2rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
`;

const Container = styled.div`
  margin-bottom: 6rem;

  img {
    max-width: ${rem(220)};
    width: 100%;
  }

  ${styles.media.lessThan('medium')`

    img {
      max-width: ${rem(166)};
    }
  `};
`;

const Header = () => {
  return (
    <Wrapper>
      <Grid.Row size="expanded">
        <Grid.Column>
          <Container>
            <Link to="/">
              <img src={withPrefix('/img/logo-title.svg')} alt="Air Con Serve" />
            </Link>
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Wrapper>
  );
};

export default Header;
